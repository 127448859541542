.contact-section{
    background: linear-gradient(to top, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
}

.form-control{
    box-shadow: $box-shadow-black;
}

.wave-contact::after {
    content: '';
	width: 100%;
    position: initial;
    display: block;
    height: 40px;
    background-size: 40px 100%;
    background-image: linear-gradient(135deg, rgb(255, 255, 255) 25%, transparent 25%), linear-gradient(225deg, rgb(255, 255, 255) 25%, transparent 25%);
    background-position: 0 0;
}
.about{
	background-color: rgb(233, 236, 239);
    position: relative;
    &__img {
        width: 18.4rem;
        height: 18.4rem;
        border-radius: 50%;
        box-shadow: $box-shadow-black;
        border: 0.138rem ridge $darkorchid;
        object-fit: cover;
    }
    &__title {
	    color: $darkorchid;
    }
}

.wave::after {
    content: '';
    position: absolute;
    right: 0;
    left: -0%;
    top: 100%;
    z-index: 10;
    display: block;
    height: 40px;
    background-size: 40px 100%;
    background-image: linear-gradient(135deg, rgb(233, 236, 239) 25%, transparent 25%), linear-gradient(225deg, rgb(233, 236, 239) 25%, transparent 25%);
    background-position: 0 0;
}
.btn-scrolltop{
    position: fixed;
    width: 90px;
    bottom: 30px;
    right: 40px;
    padding: 15px 20px;
    border: none;
    background-color: transparent;
    scroll-behavior: smooth;
    transform: translateY(100px);
    z-index: 1;
}

.btn-scrolltop-on{
    transform: translateY(0);
}

.btnup{
    width: 50px;
}
.container1{
	padding-block: 100px;
	display: flex;
    justify-content: center;
}

.hero-container{
	background: linear-gradient(to left top, rgb(254, 240, 138), rgba(251, 207, 232, 0.9), rgb(244, 114, 182));
	border-radius: 10px;
	width: 80vw;
}

.quote{
	font-weight: 300;
}

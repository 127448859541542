.card-title {
    color: $darkorchid;
}

.div-img{
    text-align: center;
}

.img-project{
    height: 150px;
    width: 300px;
    margin-block: 20px;
}


.fab{
    height: 20px;
    width: 20px;
    color: black;
}

.tec{
    text-align: center;
}